import { Action } from "redux";
import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import Types from "Types";

import Auth from "../lib/auth";
import { setView } from "./view";

const auth = new Auth();

export const signIn = (email: string, password: string) => async (dispatch: Types.AppDispatch) => {
    try {
        dispatch(setView("Loading"));
        await auth.signIn(email, password);
    } catch (e) {
        dispatch(setView("Login"));
    }
};

export const onAuthStateChangedMiddleware: Middleware = ({ dispatch }:
MiddlewareAPI<Types.AppDispatch, Types.RootState>) => {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            dispatch(setView("App"));
        } else if (["development", "test"].includes(process.env.NODE_ENV || "")) {
            dispatch(signIn("dev@lillalivsgarderob.se", "developer1234"));
        } else {
            dispatch(setView("Login"));
        }
    });

    return (next: Types.AppDispatch) => (action: Action) => next(action);
};

export const signOut = () => async (dispatch: Types.AppDispatch) => {
    dispatch(setView("Loading"));
    await auth.signOut();
};
