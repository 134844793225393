import * as firebase from "firebase/database";
import { db } from "./firebase";
import { SubmissionItem } from "./types";

export default class Submissions {
    db: firebase.Database;

    ref: firebase.DatabaseReference;

    constructor() {
        this.db = db;
        this.ref = firebase.ref(this.db, "products");
    }

    async push(item: SubmissionItem): Promise<SubmissionItem> {
        const { key: id } = await firebase.push(this.ref, item);

        return { id: id || undefined, ...item };
    }

    private async fetch(...constraints: firebase.QueryConstraint[]): Promise<SubmissionItem[]> {
        const snapshot = await firebase.get(firebase.query(this.ref, ...constraints));

        if (snapshot.exists()) {
            const val = snapshot.val();

            return Object.keys(val).map((key) => ({ ...val[key], id: key }));
        }

        return [];
    }

    async fetchLast(n: number): Promise<SubmissionItem[]> {
        return this.fetch(firebase.limitToLast(n));
    }

    async fetchSubmission(submission: number): Promise<SubmissionItem[]> {
        return this.fetch(firebase.orderByChild("submission"), firebase.equalTo(submission));
    }

    async fetchSubmissionRange(from: number, to:number): Promise<SubmissionItem[]> {
        return this.fetch(firebase.orderByChild("submission"), firebase.startAt(from), firebase.endAt(to));
    }

    async delete(items: SubmissionItem[]): Promise<void> {
        const ios = [];
        const cache = new Map<number, SubmissionItem[]>();

        const itemsCopy = await Promise.all(items.map(async (item) => {
            if (!item.id?.startsWith("@@")) {
                return { ...item };
            }

            if (!cache.has(item.submission)) {
                cache.set(item.submission, await this.fetchSubmission(item.submission));
            }

            const cacheItems = cache.get(item.submission);
            let found = cacheItems?.find(
                ({ submission, price, id }) => item.submission === submission
                && item.price === price
                && !items.find((i) => i.id === id),
            );

            if (!found) {
                throw new Error(`Failed to delete item ${item}; not found in database`);
            }

            found = { ...found };

            if (cacheItems) {
                cache.set(item.submission, cacheItems.splice(cacheItems.indexOf(found), 1));
            }

            return found;
        }));

        if (itemsCopy.some((item) => item.id === undefined || item.id === null)) {
            throw Error("An item has no ID set, cannot delete");
        }

        /* Chunk it up. */
        while (itemsCopy.length > 0) {
            const chunk = itemsCopy.splice(0, Math.min(100, items.length));

            ios.push(firebase.update(
                this.ref,
                chunk.reduce(
                    (acc, item) => ({ ...acc, [item.id || ""]: null }),
                    {},
                ),
            ));
        }

        await Promise.all(ios);
    }

    async deleteBySubmission(submission: number): Promise<void> {
        await this.delete(await this.fetchSubmission(submission));
    }
}
