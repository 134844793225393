import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";
import Types from "Types";
import Submissions from "../lib/submissions";
import { SubmissionItem, SubmissionSpecialIds } from "../lib/types";

export const subs = new Submissions();
export const PAGE_SIZE = 5;

interface SubmissionsState {
    items: Array<SubmissionItem>;
    status: "loading" | "finished" | "error";
    ctr: number;
}

const createSubmissionsSlice = <Reducers extends SliceCaseReducers<SubmissionsState>>({
    name = "",
    initialState = {
        items: [],
        status: "finished",
        ctr: 0,
    },
    reducers,
}: {
    name: string;
    initialState: SubmissionsState;
    reducers: ValidateSliceCaseReducers<SubmissionsState, Reducers>;
}) => createSlice({
    name,
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        setItems: (
            state: SubmissionsState,
            action: PayloadAction<Array<SubmissionItem>>,
        ) => {
            state.items = action.payload;
        },
        pushItems: (
            state: SubmissionsState,
            action: PayloadAction<SubmissionItem>,
        ) => {
            state.items.push({ id: `@@${state.ctr}`, ...action.payload });
            state.ctr += 1;
        },
        removeItems: (
            state: SubmissionsState,
            action: PayloadAction<Array<SubmissionItem>>,
        ) => {
            /* eslint-disable no-restricted-syntax */
            for (const deleteItem of action.payload) {
                state.items = state.items.filter(
                    ({ id }) => id !== deleteItem.id,
                );
            }
            /* eslint-enable no-restricted-syntax */
        },
        clearItems: (state: SubmissionsState) => {
            state.items = [];
        },
        setStatus: (
            state: SubmissionsState,
            action: PayloadAction<SubmissionsState["status"]>,
        ) => {
            state.status = action.payload;
        },
        ...reducers,
        /* eslint-enable no-param-reassign */
    },
});

export const registeredItemsSlice = createSubmissionsSlice({
    name: "submissions/registeredItems",
    initialState: {
        items: [],
        status: "finished",
        ctr: 0,
    },
    reducers: {},
});

export const filteredItemsSlice = createSubmissionsSlice({
    name: "submissions/filteredItems",
    initialState: {
        items: [],
        status: "finished",
        ctr: 0,
    },
    reducers: {},
});

/* Items about to be cleared. */
export const clearableItemsSlice = createSubmissionsSlice({
    name: "submissions/clearableItems",
    initialState: {
        items: [],
        status: "finished",
        ctr: 0,
    },
    reducers: {},
});

export const getLastRegisteredItems = (page: number) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.setStatus("loading"));
    dispatch(registeredItemsSlice.actions.clearItems());
    dispatch(registeredItemsSlice.actions.setItems(await subs.fetchLast((page + 1) * PAGE_SIZE)));
    dispatch(registeredItemsSlice.actions.setStatus("finished"));
};

export const getItemsBySubmission = (subm: number) => async (dispatch: Types.AppDispatch) => {
    dispatch(filteredItemsSlice.actions.setStatus("loading"));
    dispatch(filteredItemsSlice.actions.clearItems());
    dispatch(filteredItemsSlice.actions.setItems(await subs.fetchSubmission(subm)));
    dispatch(filteredItemsSlice.actions.setStatus("finished"));
};

export const registerItem = (item: SubmissionItem) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.pushItems(item));
};

export const registerGiftCard = (sum: number) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.pushItems({
        submission: SubmissionSpecialIds.GIFT_CARD,
        price: sum,
    }));
};

export const clearRegisteredItems = (items?: SubmissionItem[]) => (dispatch: Types.AppDispatch) => {
    if (items) {
        dispatch(registeredItemsSlice.actions.removeItems(items));
    } else {
        dispatch(registeredItemsSlice.actions.clearItems());
    }
};

export const deleteItems = (items: SubmissionItem[]) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.setStatus("loading"));
    dispatch(filteredItemsSlice.actions.clearItems());
    dispatch(clearableItemsSlice.actions.clearItems());

    await subs.delete(items);
    dispatch(registeredItemsSlice.actions.clearItems());
    dispatch(registeredItemsSlice.actions.setStatus("finished"));
};

export const getItemsBySubmissionRange = (from: number, to: number) => async (
    dispatch: Types.AppDispatch,
) => {
    dispatch(clearableItemsSlice.actions.setStatus("loading"));
    dispatch(clearableItemsSlice.actions.clearItems());

    dispatch(clearableItemsSlice.actions.setItems(await subs.fetchSubmissionRange(from, to)));
    dispatch(clearableItemsSlice.actions.setStatus("finished"));
};
