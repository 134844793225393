import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Paper,
    Typography,
    Alert,
    Button,
    CircularProgress,
} from "@mui/material";
import { useAppSelector, useAppDispatch, RootState } from "../store";
import { registerItem, registerGiftCard, clearRegisteredItems } from "../ducks/submissions";
import { SelectableTable, AlignType, BodyType } from "./SelectableTable";
import InputGroup from "./InputGroup";
import { createReceipt, createZReport } from "../ducks/pos";
import { SubmissionSpecialIds } from "../lib/types";

const headerConfig = {
    order: ["submission", "price"],
    config: {
        submission: {
            text: "Inlämningsnummer",
            align: "right" as AlignType,
        },
        price: {
            text: "Pris (kr)",
            align: "right" as AlignType,
        },
    },
};

export default () => {
    const dispatch = useAppDispatch();
    const { items, status } = useAppSelector(
        (state: RootState) => state.registeredItems,
    );
    const { error: receiptError } = useAppSelector(
        (state: RootState) => state.pos,
    );
    const { queueLength } = useAppSelector(
        (state: RootState) => state.receiptPrinter,
    );
    const { queueLength: zReportQueueLength } = useAppSelector(
        (state: RootState) => state.zReportPrinter,
    );
    const makeItemsTableBody = () => items.map((item) => {
        if (item.submission === SubmissionSpecialIds.GIFT_CARD) {
            return {
                ...item,
                submission: "Presentkort",
            };
        }

        return ({ ...item });
    }).reverse();

    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [itemsTableBody, setItemsTableBody] = useState<BodyType[]>(makeItemsTableBody());
    const [refs, setRefs] = useState<any[]>(undefined);
    const [sum, setSum] = useState<number>(0);
    const [zreportDialogOpen, setZreportDialogOpen] = useState<boolean>(false);
    const [refundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
    const [paymentInput, setPaymentInput] = useState<number[]>([0, 0, 0]);

    const verifyInput = (input: string[]) => input.every((val) => /^\d*$/.test(val));
    const onRegisterInputChange = (input: string[], givenRefs: any[]) => {
        setRefs(givenRefs);

        // Always focus on "Pris" once "Inlämningsnummer" has length 4.
        if (input[0].length === 4) {
            givenRefs[1].current.focus();
        }
    };
    const onRegisterInput = async (input: string[]) => {
        await dispatch(registerItem({
            submission: parseInt(input[0], 10),
            price: parseInt(input[1], 10),
        }));
        refs[0].current.focus();
    };
    const onGiftCardInput = async (input: string[]) => {
        await dispatch(registerGiftCard(parseInt(input[0], 10)));
        refs[0].current.focus();
    };
    const verifyPaymentInput = (input: string[]) => input
        .every((val) => /^\d*$/.test(val)
            && parseInt(input[0], 10)
            + parseInt(input[1], 10)
            + parseInt(input[2], 10) === sum);
    const registerPayment = (input: number[], op: "PURCHASE" | "REFUND") => {
        dispatch(createReceipt(
            [
                {
                    description: "Kort",
                    paymentTotal: input[0],
                },
                {
                    description: "Swish",
                    paymentTotal: input[1],
                },
                {
                    description: "Presentkort",
                    paymentTotal: input[2],
                },
            ],
            op,
        ));
    };
    const onPaymentInput = (input: string[], btnIdx: number) => {
        const inputParsed = input.map((str) => parseInt(str, 10));

        setPaymentInput(inputParsed);

        if (btnIdx === 0) {
            registerPayment(inputParsed, "PURCHASE");
        } else {
            setRefundDialogOpen(true);
        }
    };
    const onCheck = (ids: KeyType[]) => {
        setSelectedKeys(ids);
    };
    const onDelete = () => {
        dispatch(clearRegisteredItems(items.filter((item) => selectedKeys.includes(item.id))));
    };

    useEffect(() => {
        setSelectedKeys([]);
        setItemsTableBody(makeItemsTableBody());
        setSum(items.reduce((s, item) => s + item.price, 0));
    }, [items]);

    useEffect(() => {
        if (refs && queueLength === 0) {
            refs[0].current.focus();
        }
    }, [queueLength]);

    return (
        <Paper sx={{ padding: 2 }}>
            <Dialog open={zreportDialogOpen} onClose={() => setZreportDialogOpen(false)}>
                <DialogTitle>
                    Bekräfta val
                </DialogTitle>
                <DialogContent>
                    Vill du verkligen skapa och skriva ut Z-rapport?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setZreportDialogOpen(false)}
                        disabled={zReportQueueLength > 0}
                    >
                        Avbryt
                    </Button>
                    <Button
                        onClick={async () => {
                            await dispatch(createZReport());
                            setZreportDialogOpen(false);
                        }}
                        disabled={zReportQueueLength > 0}
                    >
                        Bekräfta {zReportQueueLength > 0
                            && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={refundDialogOpen} onClose={() => setRefundDialogOpen(false)}>
                <DialogTitle>
                    Bekräfta val
                </DialogTitle>
                <DialogContent>
                    Registrera retur på {items.length} varor med summan {sum} kr?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setRefundDialogOpen(false)}
                        disabled={queueLength > 0}
                    >
                        Avbryt
                    </Button>
                    <Button
                        onClick={async () => {
                            registerPayment(paymentInput, "REFUND");
                            setRefundDialogOpen(false);
                        }}
                        disabled={queueLength > 0}
                    >
                        Bekräfta {queueLength > 0
                            && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                    <Typography variant="h5">Registrera artikel</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup
                        labels={["Inlämningsnummer", "Pris"]}
                        buttonLabel="Registrera"
                        verifyInput={verifyInput}
                        onInputChange={onRegisterInputChange}
                        onButtonClick={onRegisterInput}
                        loading={status === "loading" || queueLength > 0}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Registrera presentkort</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <InputGroup
                        labels={["Summa"]}
                        buttonLabel="Registrera presentkort"
                        verifyInput={verifyInput}
                        onButtonClick={onGiftCardInput}
                        loading={status === "loading" || queueLength > 0}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectableTable
                        headers={headerConfig}
                        body={itemsTableBody}
                        onCheck={onCheck}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {`Summa ${sum} kr`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {selectedKeys.length > 0
                        && <Button color="error" onClick={onDelete}>
                            Ta bort {selectedKeys.length} {selectedKeys.length > 1 ? "varor" : "vara"}
                        </Button>
                    }
                </Grid>
                <Grid item xs={12}>
                    <InputGroup
                        labels={["Summa kort", "Summa Swish", "Summa presentkort"]}
                        buttonLabel="Registrera betalning,Registrera retur"
                        verifyInput={verifyPaymentInput}
                        onButtonClick={onPaymentInput}
                        defaultValues={["0", sum.toString(), "0"]}
                        loading={status === "loading" || queueLength > 0}
                        warningText="Kontrollera summan"
                        disabled={!items.length}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setZreportDialogOpen(true)}
                        disabled={zReportQueueLength > 0}
                    >
                        Skapa Z-rapport
                        {zReportQueueLength > 0
                            && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {receiptError
                        && <Alert severity={"error"}>
                            Något gick fel, försök igen eller kontakta support
                        </Alert>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
};
