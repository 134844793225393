export enum SubmissionSpecialIds {
    GIFT_CARD = -1,
}

export interface SubmissionItem {
    id?: string;
    submission: number;
    price: number;
}

export type OpType = "PURCHASE" | "REFUND";

export interface Payment {
    description: "Kort" | "Swish" | "Presentkort";
    paymentTotal: number;
}

export interface ReceiptInfo {
    rid: number;
    articles?: SubmissionItem[];
    payments?: Payment[];
    amount: number;
    vat: number;
    orgId: string;
    storeId: number;
    registerId: number;
    date: string;
}

export const DEFAULT_RECEIPT: ReceiptInfo = {
    rid: 0,
    articles: [],
    payments: [],
    amount: 0,
    vat: 0,
    orgId: "HS04",
    storeId: 1,
    registerId: 1,
    date: (new Date()).toISOString(),
};
