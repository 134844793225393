import React, { FunctionComponent, useState } from "react";
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

interface InputGroupProps {
    labels: string[];
    buttonLabel: string;
    warningText?: string;
    verifyInput: (input: string[]) => boolean;
    onInputChange?: (input: string[], refs: any[]) => void;
    defaultValues?: string[];
    onButtonClick: (input: string[], btnIdx?: number) => void;
    noClearOnClick?: boolean;
    loading?: boolean;
    disabled?: boolean;
}

const InputGroup: FunctionComponent<InputGroupProps> = (props) => {
    const warningText = props.warningText || "Endast siffror är tillåtna värden.";
    const defaultInputs = props.labels.map(() => "");
    const refs = props.labels.map(() => React.useRef());
    const [inputs, setInputs] = useState<string[]>(defaultInputs);
    const validInput = props.verifyInput(inputs);
    const emptyInput = inputs.some((value) => value.length === 0);
    const updateInput = (index: number, value: string) => {
        inputs[index] = value;
        setInputs([...inputs]);
    };
    const buttonDisabled = () => !validInput || emptyInput || props.loading || props.disabled;
    const onButtonClick = (btnIdx: number) => () => {
        props.onButtonClick(inputs, btnIdx);

        if (!props.noClearOnClick) {
            setInputs(defaultInputs);
        }
    };

    React.useEffect(() => {
        if (props.onInputChange) {
            props.onInputChange(inputs, refs as any[]);
        }

        if (props.defaultValues) {
            setInputs(props.defaultValues);
        }
    }, [inputs, props.defaultValues, ...refs]);

    return (
        <Grid container spacing={2} direction="row">
            {props.labels.map((label, index) => (
                <Grid key={label} item xs={12}>
                    <TextField
                        disabled={props.loading || props.disabled}
                        inputRef={refs[index]}
                        label={label}
                        type={label === "Lösenord" ? "password" : "text"}
                        value={inputs[index]}
                        onChange={(event) => updateInput(index, event.target.value)}
                        onKeyDown={(event) => event.key === "Enter"
                            && !buttonDisabled()
                            && onButtonClick(0)()}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <Typography variant="body2" color="error" sx={{
                    display: !validInput && !emptyInput ? "inherit" : "none",
                }}>
                    {warningText}
                </Typography>
            </Grid>
            {props.buttonLabel.split(",").map((label: string, idx: number) => <Grid item xs={12} key={label}>
                <Button
                    disabled={buttonDisabled()}
                    variant="contained"
                    color="primary"
                    onClick={onButtonClick(idx)}
                >
                    {label}
                    {(props.loading === undefined || props.loading)
                        && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                </Button>
            </Grid>)}
        </Grid>
    );
};

export default InputGroup;
