import React from "react";
import {
    Grid,
    Paper,
    Typography,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    CircularProgress,
} from "@mui/material";
import JsBarcode from "jsbarcode";
import { XMLSerializer } from "xmldom";
import { useAppSelector, useAppDispatch, RootState } from "../store";
import { pushLabel } from "../ducks/print";

const xmlSerializer = new XMLSerializer();
const standardPrices = [
    "5",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "75",
    "100",
];
const standardSizes = [
    "50",
    "56",
    "50/56",
    "62",
    "68",
    "62/68",
    "74",
    "80",
    "74/80",
    "86",
    "92",
    "86/92",
    "98",
    "104",
    "98/104",
    "110",
    "116",
    "110/116",
    "122",
    "128",
    "122/128",
    "134",
    "140",
    "134/140",
    "146",
    "152",
    "146/152",
];

export default () => {
    const dispatch = useAppDispatch();
    const { queueLength } = useAppSelector((state: RootState) => state.labelPrinter);
    const [submissionValue, setSubmissionValue] = React.useState("");
    const [priceValue, setPriceValue] = React.useState("15");
    const [customPriceValue, setCustomPriceValue] = React.useState("100");
    const [sizeValue, setSizeValue] = React.useState("null");
    const [customSizeValue, setCustomSizeValue] = React.useState("XL");
    const barcodeRef = React.useRef();
    const svgRef = React.useRef();

    const handleSubmissionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubmissionValue((event.target as HTMLInputElement).value);
    };

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPriceValue((event.target as HTMLInputElement).value);
    };

    const handleCustomPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomPriceValue((event.target as HTMLInputElement).value);
    };

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSizeValue((event.target as HTMLInputElement).value);
    };

    const handleCustomSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomSizeValue((event.target as HTMLInputElement).value);
    };

    const priceString = priceValue === "custom" ? customPriceValue : priceValue;
    let sizeString = "";

    const onButtonClick = () => {
        dispatch(pushLabel(xmlSerializer.serializeToString(svgRef.current)));
    };

    if (sizeValue !== "null") {
        if (sizeValue === "custom") {
            sizeString = ` ${customSizeValue}`;
        } else {
            sizeString = ` ${sizeValue}`;
        }
    }

    React.useEffect(() => {
        JsBarcode(barcodeRef.current, submissionValue + priceString, {
            margin: 40,
            height: 70,
        });

        const barcodeElement = document.getElementById("barcode");
        const containerElement = document.getElementById("barcode_container");

        containerElement.setAttribute("width", barcodeElement.getAttribute("width"));
    });

    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Typography variant="h5">
                        Skriv ut etikett
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        value={submissionValue}
                        onChange={handleSubmissionValueChange}
                        label="Inlämningsnummer"
                    />
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel>Pris</FormLabel>
                                <RadioGroup
                                    value={priceValue}
                                    onChange={handlePriceChange}
                                >
                                    {standardPrices.map((price) => (
                                        <FormControlLabel
                                            key={price}
                                            value={price}
                                            control={<Radio />}
                                            label={`${price} kr`}
                                        />
                                    ))}
                                    <FormControlLabel
                                        value="custom"
                                        control={<Radio />}
                                        label={
                                            <TextField
                                                value={customPriceValue}
                                                onChange={handleCustomPriceChange}
                                                variant="standard"
                                                disabled={priceValue !== "custom"}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel>Storlek</FormLabel>
                                <RadioGroup
                                    value={sizeValue}
                                    onChange={handleSizeChange}
                                >
                                    <FormControlLabel value="null" control={<Radio />} label="Ingen" />
                                    {standardSizes.map((size) => (
                                        <FormControlLabel
                                            key={size}
                                            value={size}
                                            control={<Radio />}
                                            label={size}
                                        />
                                    ))}
                                    <FormControlLabel
                                        value="custom"
                                        control={<Radio />}
                                        label={
                                            <TextField
                                                value={customSizeValue}
                                                onChange={handleCustomSizeChange}
                                                variant="standard"
                                                disabled={sizeValue !== "custom"}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormLabel>Förhandsvisning</FormLabel>
                </Grid>
                <Grid item>
                    <svg id="barcode_container" ref={svgRef}>
                        <g id="barcode" ref={barcodeRef} />
                        <text x="50%" y={32} textAnchor="middle" style={{
                            font: "20px monospace",
                        }}>
                            {`${priceString}kr${sizeString}`}
                        </text>
                    </svg>
                </Grid>
                <Grid item>
                    <Button
                        disabled={!(/^\d{4}$/.test(submissionValue)) || queueLength > 0}
                        onClick={onButtonClick}
                        color="primary"
                        variant="contained"
                    >
                        Skriv ut
                        {queueLength > 0
                            && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};
