import * as firebase from "./firebase";
import { SubmissionItem, Payment, OpType } from "./types";

export async function createReceipt(articles: SubmissionItem[], payments: Payment[], op: OpType) {
    const result = await firebase.createReceipt({ articles, payments, op });

    return result.data;
}

export async function createZReport() {
    const result = await firebase.createZReport();

    return result.data;
}
