import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Types from "Types";
import {
    registeredItemsSlice, clearRegisteredItems, deleteItems, subs,
} from "./submissions";
import * as pos from "../lib/pos";
import {
    SubmissionItem, SubmissionSpecialIds, ReceiptInfo, OpType, Payment, DEFAULT_RECEIPT,
} from "../lib/types";
import { pushZReport, pushReceipt } from "./print";

interface POSState {
    error?: string;
    receiptInfo: ReceiptInfo;
}

const initialState: POSState = {
    error: undefined,
    receiptInfo: DEFAULT_RECEIPT,
};

const posSlice = createSlice({
    name: "pos",
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        setReceiptInfo: (state, action: PayloadAction<ReceiptInfo>) => {
            state.receiptInfo = action.payload;
        },
        /* eslint-enable no-param-reassign */
    },
});

export default posSlice.reducer;

export const createReceipt = (
    payments: Payment[],
    op: OpType,
) => async (dispatch: Types.AppDispatch, getState: () => Types.RootState) => {
    const articles: SubmissionItem[] = getState().registeredItems.items;

    try {
        dispatch(posSlice.actions.setError(undefined));
        dispatch(registeredItemsSlice.actions.setStatus("loading"));

        const receiptInfo = await pos.createReceipt(articles, payments, op);

        dispatch(posSlice.actions.setReceiptInfo({
            ...receiptInfo,
            articles,
            payments,
        }));
        dispatch(clearRegisteredItems());

        if (op === "PURCHASE") {
            await Promise.all(articles.map((article) => {
                if (article.submission !== SubmissionSpecialIds.GIFT_CARD) {
                    return subs.push(article);
                }

                return Promise.resolve();
            }));
        } else if (op === "REFUND") {
            await dispatch(deleteItems(articles));
        }

        dispatch(registeredItemsSlice.actions.setStatus("finished"));
        await dispatch(pushReceipt(receiptInfo));
    } catch (e) {
        dispatch(registeredItemsSlice.actions.setStatus("finished"));
        dispatch(posSlice.actions.setError(e.message));
        throw e;
    }
};

export const createZReport = () => async (dispatch: Types.AppDispatch) => {
    try {
        dispatch(posSlice.actions.setError(undefined));
        dispatch(registeredItemsSlice.actions.setStatus("loading"));

        const zReport = await pos.createZReport();

        dispatch(await pushZReport(zReport));

        dispatch(registeredItemsSlice.actions.setStatus("finished"));
    } catch (e) {
        dispatch(registeredItemsSlice.actions.setStatus("finished"));
        dispatch(posSlice.actions.setError(e.message));
        throw e;
    }
};
