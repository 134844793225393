import * as firebase from "firebase/database";
import { db } from "./firebase";
import { ReceiptInfo } from "./types";

export default class Printer {
    db: firebase.Database;

    ref: firebase.DatabaseReference;

    constructor(channel: string) {
        this.db = db;
        this.ref = firebase.ref(this.db, `printQueue/${channel}`);
    }

    async push(data: string | ReceiptInfo): Promise<void> {
        await firebase.push(this.ref, data);
    }

    async pop(): Promise<string | ReceiptInfo> {
        return new Promise((resolve) => {
            const unsubscribe = firebase.onValue(firebase.query(
                this.ref,
                firebase.orderByKey(),
                firebase.limitToFirst(1),
            ), (snapshot) => {
                if (snapshot.hasChildren()) {
                    unsubscribe();

                    snapshot.forEach((child) => {
                        resolve(child.val());
                        firebase.update(this.ref, {
                            [child.key]: null,
                        });
                    });
                }
            });
        });
    }

    async peek(): Promise<string | ReceiptInfo> {
        return new Promise((resolve) => {
            const unsubscribe = firebase.onValue(firebase.query(
                this.ref,
                firebase.orderByKey(),
                firebase.limitToFirst(1),
            ), (snapshot) => {
                if (snapshot.hasChildren()) {
                    unsubscribe();

                    snapshot.forEach((child) => {
                        resolve(child.val());
                    });
                }
            });
        });
    }

    async count() {
        const snapshot = await firebase.get(firebase.query(this.ref));

        return snapshot.size;
    }

    onCountChanged(listener: (count: number) => void) {
        return firebase.onValue(firebase.query(this.ref), (snapshot) => {
            listener(snapshot.size);
        });
    }
}
