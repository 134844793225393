import React, { useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import { useAppSelector, useAppDispatch, RootState } from "../store";
import {
    getItemsBySubmissionRange,
    deleteItems,
} from "../ducks/submissions";
import InputGroup from "./InputGroup";

export default () => {
    const dispatch = useAppDispatch();
    const { items, status } = useAppSelector(
        (state: RootState) => state.clearableItems,
    );
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const verifyInput = (input: string[]) => input.every((val) => /^\d*$/.test(val));
    const onButtonClick = async (input: string[]) => {
        await dispatch(getItemsBySubmissionRange(
            parseInt(input[0], 10),
            parseInt(input[1], 10),
        ));
        setDialogOpen(true);
    };

    const onDialogClose = () => status === "finished" && setDialogOpen(false);
    const onDelete = async () => {
        await dispatch(deleteItems(items));
        setDialogOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog open={items.length === 0 && dialogOpen} onClose={onDialogClose}>
                <DialogContent>
                    Inga varor hittade.
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDialogClose}>Stäng</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!items.length && dialogOpen} onClose={onDialogClose}>
                <DialogTitle>Ta bort varor permanent</DialogTitle>
                <DialogContent>
                    {items.length} varor kommer tas bort permanent från databasen.
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDialogClose} disabled={status !== "finished"}>Avbryt</Button>
                    <Button onClick={onDelete} disabled={status !== "finished"}>
                        Ta bort {status === "loading"
                            && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper sx={{ padding: 2 }}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <Typography variant="h5">Ta bort artiklar</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup
                            labels={["Från inlämningsnummer", "Till inlämningsnummer"]}
                            buttonLabel="Ta bort"
                            verifyInput={verifyInput}
                            onButtonClick={onButtonClick}
                            loading={status === "loading"}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
