import {
    createSlice,
    Middleware,
    MiddlewareAPI,
    PayloadAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";
import { Action } from "redux";
import Types from "Types";
import { ReceiptInfo } from "../lib/types";

import Printer from "../lib/printer";

const labelPrinter = new Printer("labels");
const receiptPrinter = new Printer("receipts");
const zReportPrinter = new Printer("zreport");

interface PrintState {
    queueLength: number;
}

const createPrinterSlice = <Reducers extends SliceCaseReducers<PrintState>>({
    name = "",
    initialState = {
        queueLength: 0,
    },
    reducers,
}: {
    name: string;
    initialState: PrintState,
    reducers: ValidateSliceCaseReducers<PrintState, Reducers>;
}) => createSlice({
    name,
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        setQueueLength: (state, action: PayloadAction<number>) => {
            state.queueLength = action.payload;
        },
        ...reducers,
        /* eslint-enable no-param-reassign */
    },
});

export const labelPrinterSlice = createPrinterSlice({
    name: "printer/labels",
    initialState: {
        queueLength: 0,
    },
    reducers: {},
});

export const receiptPrinterSlice = createPrinterSlice({
    name: "printer/receipts",
    initialState: {
        queueLength: 0,
    },
    reducers: {},
});

export const zReportPrinterSlice = createPrinterSlice({
    name: "printer/zreport",
    initialState: {
        queueLength: 0,
    },
    reducers: {},
});

export const onPrintQueueUpdate: Middleware = ({ dispatch }:
MiddlewareAPI<Types.AppDispatch, Types.RootState>) => {
    labelPrinter.onCountChanged((count) => {
        dispatch(labelPrinterSlice.actions.setQueueLength(count));
    });
    receiptPrinter.onCountChanged((count) => {
        dispatch(receiptPrinterSlice.actions.setQueueLength(count));
    });
    zReportPrinter.onCountChanged((count) => {
        dispatch(zReportPrinterSlice.actions.setQueueLength(count));
    });

    return (next: Types.AppDispatch) => (action: Action) => next(action);
};

export const pushLabel = (svg: string) => async () => {
    await labelPrinter.push(svg);
};

export const pushReceipt = (receiptInfo: ReceiptInfo) => async () => {
    await receiptPrinter.push(receiptInfo);
};

export const pushZReport = (zreport: string) => async () => {
    await zReportPrinter.push(zreport);
};
